import cx from 'classnames';

import styles from './preheader.module.scss';

export interface PreheaderProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
  className?: string;
}

export function Preheader({ left, right, className }: PreheaderProps) {
  // Even if we don't have any preheader actions, we still want to render the
  //   container so the padding at the top of all pages is consistent.
  return (
    <div className={cx(styles.root, className)}>
      {left ?? <div />}
      {right ?? <div />}
    </div>
  );
}
