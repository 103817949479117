import { useState } from 'react';

import cx from 'classnames';

import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonType } from '~/v1/components/button/button.interface';

import { AccordionTextItem } from './item/item';
import { type AccordionTextType } from './text.interface';
import styles from './text.module.scss';

interface AccordionTextProps {
  className?: string;
  title: string;
  items: AccordionTextType[];
}

const DEFAULT_DISPLAY_LIMIT = 5;

export const AccordionText: React.FC<AccordionTextProps> = ({ className, title, items }) => {
  const [showAll, setShowAll] = useState(false);

  const onShowAll = () => setShowAll(!showAll);

  const data = showAll ? items : items.slice(0, DEFAULT_DISPLAY_LIMIT);

  return (
    <div className={className}>
      <div className={cx(styles.title, 'modulesTitleTypography')}>{title}</div>

      {data.map((item, index) => (
        <AccordionTextItem key={`${item.question}-${index}`} {...item} />
      ))}

      {items.length > DEFAULT_DISPLAY_LIMIT && (
        <Button
          className={styles.button}
          type={ButtonType.Secondary}
          mode={ButtonMode.Light}
          onClick={onShowAll}
          text={`See ${showAll ? 'fewer' : 'all'} questions`}
        />
      )}
    </div>
  );
};
