import cx from 'classnames';

import { formatDate } from '~/v1/utils/format';

import styles from './creditSection.module.scss';

export interface Credits {
  location?: string;
  grantmakingArea?: string;
  author?: string;
  photography?: string;
  date?: string;
  [label: string]: string | undefined;
}

export interface CreditSectionProps {
  credits: Credits;
  rawDate?: boolean;
  className?: string;
}

export function CreditSection({ credits, rawDate = false, className }: CreditSectionProps) {
  if (Object.values(credits).filter(Boolean).length <= 0) {
    return null;
  }

  const { location, grantmakingArea, author, photography, date, ...rest } = credits;

  return (
    <div className={cx(styles.root, className)}>
      {location && <Credit label="Location" credit={location} />}
      {grantmakingArea && <Credit label="Grantmaking area" credit={grantmakingArea} />}
      {author && <Credit label="Author" credit={author} />}
      {photography && <Credit label="Photography" credit={photography} />}
      {Object.entries(rest).map(([label, credit]) => (
        <Credit key={label} label={label} credit={credit || ''} />
      ))}
      {date && <Credit label="Date" credit={rawDate ? date : formatDate(date)} />}
    </div>
  );
}

function Credit({ label, credit }: { label: string; credit: string }) {
  return (
    <div className="paragraphTypography">
      <span className={styles.creditLabel}>{label}</span>
      {credit}
    </div>
  );
}
