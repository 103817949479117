'use client';

import { useContext } from 'react';

import { IconType } from '~/v1/components/icons/icon.interfaces';
import { Link } from '~/v1/components/link/link';

import styles from './breadcrumb.module.scss';
import { BreadcrumbContext } from './context';

export function Breadcrumb() {
  const { previousPage } = useContext(BreadcrumbContext);

  if (previousPage) {
    return (
      <div className={styles.root}>
        <Link
          href={{
            pathname: previousPage.path,
            query: { pop: true },
          }}
          as={previousPage.path}
          withIcon={IconType.ExpandingArrowLeft}
          reverseIcon
          className="linkTypography"
        >
          {previousPage.label}
        </Link>
      </div>
    );
  }

  return <div />;
}
